<template>
    <section>
        <top-alternative-section
            :image="image"
            :title="$t('gestion.top.title')"
            :content="$t('gestion.top.content')"
        ></top-alternative-section>
        
        <features-section
          :title="$t('gestion.featuresSection.title')"
          :description="$t('gestion.featuresSection.description')"
          :items="$t('gestion.featuresSection.items')"
        >
        </features-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/gestion.png"
  
  export default {
    metaInfo: {
    title: 'Puntos de Venta',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Opera tus sucursales y tiendas en línea desde cualquier lugar y en todo momento.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
    },
    components: {
      TopAlternativeSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  