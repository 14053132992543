<template>
  <section>
    <top-section
      :image="LightImage"
      :title="$t('home.top.title')"
      :description="$t('home.top.description')"
    ></top-section>

    <description-section
      :title="$t('home.description.title')"
      :content="$t('home.description.content')"
    ></description-section>

    <features-section
      :title="$t('home.featuresSection.title')"
      :description="$t('home.featuresSection.description')"
      :items="$t('home.featuresSection.items')"
    >
    </features-section>

    <offers-section 
      :items="$t('home.offersSection.items')"
    ></offers-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopSection from "../sections/TopSection.vue";
import DescriptionSection from "../sections/DescriptionSection.vue";
import OffersSection from "../sections/OffersSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import LightImage from "../../assets/principal.png"

export default {
  metaInfo: {
    title: 'OPOS+ Bienestar',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Orquestación de extremo a extremo en servicios de salud, estudios clínicos y entrega de resultados en diferentes canales de venta (físicos y digitales)'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    TopSection,
    DescriptionSection,
    OffersSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      LightImage: LightImage
    };
  }
};
</script>
