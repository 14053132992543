<template>
    <section>
        <top-alternative-section
            :image="image"
            :title="$t('laboratorio.top.title')"
            :content="$t('laboratorio.top.content')"
        ></top-alternative-section>
        
        <features-section
          :title="$t('laboratorio.featuresSection.title')"
          :description="$t('laboratorio.featuresSection.description')"
          :items="$t('laboratorio.featuresSection.items')"
        >
        </features-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/ruteo.png"
  
  export default {
    metaInfo: {
    title: 'Laboratorio Omnicanal',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Aumenta tus ventas con un software capaz de unificar el comercio en tienda, en línea y móvil, brindándote así mayor visibilidad de inventario, datos de ventas, conciliación de pagos y preferencias de tus clientes.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
    },
    components: {
      TopAlternativeSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  