<template>
    <section>
        <top-alternative-section
            :image="image"
            :title="$t('citas.top.title')"
            :content="$t('citas.top.content')"
        ></top-alternative-section>
        
        <features-section
          :title="$t('citas.featuresSection.title')"
          :description="$t('citas.featuresSection.description')"
          :items="$t('citas.featuresSection.items')"
        >
        </features-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/citas.png"
  
  export default {
    metaInfo: {
    title: 'Gestión de Citas',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Visibilidad para procesar más citas en menos tiempo para evitar retrasos y cuellos de botella.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
    },
    name: "Prevención y Monitoreo",
    components: {
      TopAlternativeSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  