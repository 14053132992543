<template>
  <section>
    <top-alternative-section
        :image="image"
        :title="$t('suministro.top.title')"
        :content="$t('suministro.top.content')"
    ></top-alternative-section>

    <features-section
      :title="$t('suministro.featuresSection.title')"
      :description="$t('suministro.featuresSection.description')"
      :items="$t('suministro.featuresSection.items')"
    >
    </features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import LightImage from "../../assets/distribucion.png"

export default {
  metaInfo: {
    title: 'Gestión de Pedidos',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'La rapidez y precisión de los resultados son fundamentales para la toma de decisiones médicas y la atención al paciente'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  